// Import all of Bootstrap's CSS
@import "bootstrap/scss/bootstrap";

$main-color-1: #E82837;
$main-color-2: #f2f2f2;
$main-color-3: #373737;
$main-color-4: #222221;
$main-color-5: #a8a8a8;

@font-face {
  font-family: 'conthrax';
  src: url(../fonts/conthrax-sb.otf);
}

@font-face {
  font-family: 'effra-r';
  src: url(../fonts/Effra-regular.ttf);
}

@font-face {
  font-family: 'effra-m';
  src: url(../fonts/Effra_Std_Md.ttf);
}
@font-face {
  font-family: 'effra-b';
  src: url(../fonts/Effra_Std_Bd.ttf);
}
@font-face {
  font-family: 'effra-li';
  src: url(../fonts/Effra_Std_LtIt.ttf);
}

html, body, body div, span, object, iframe, h1, h2, h3, h4, h5, h6, p, blockquote, pre, abbr, address, cite, code, del, dfn, em, img, ins, kbd, q, samp, small, strong, sub, sup, var, b, i, dl, dt, dd, ol, ul, li, fieldset, form, label, legend, table, caption, tbody, tfoot, thead, tr, th, td, article, aside, figure, footer, header, hgroup, menu, nav, section, time, mark, audio, video {
  margin: 0;
  padding: 0;
  border: 0;
  outline: 0;
  font-size: 100%;
  vertical-align: baseline;
  background: transparent;
}

html {
  overflow: auto;
}

body {
  font-family: 'effra-r';
  background: #fff;
}

a, button {
  transition: all 200ms ease-out;
}

section {
  margin-bottom: 180px;
}

@media (max-width: 991px) {
  section {
    margin-bottom: 150px;
  }
}

.grey-bg {
  background-color: $main-color-2;
  padding: 180px 0 90px 0;
}

.overlay {
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  display: flex;
  background-color: #fff;
  z-index: 999;
  justify-content: center;
  svg {
    width: 550px;
    max-width: 100%;
  }
}

.buttonV1 {
  background: $main-color-1 !important;
  border: 1px solid $main-color-1 !important;
  border-radius: 3px;
  color: #fff !important;
  font-size: 17px;
  text-transform: uppercase;
  padding: 5px 40px;
  letter-spacing: 4px;
  text-decoration: none;
  &:hover {
    background-color: $main-color-4 !important;
    border-color: $main-color-4 !important;
  }
}

.buttonV2 {
  display: flex;
  width: 200px;
  height: 40px;
  border-radius: 3px;
  background: transparent;
  border: 1px solid $main-color-4;
  color: $main-color-4;
  font-size: 17px;
  text-transform: uppercase;
  justify-content: center;
  align-items: center;
  text-decoration: none;
  letter-spacing: 4px;
  margin-right: 20px;
  &:hover {
    background: $main-color-1;
    border: 1px solid $main-color-1;
    color: #fff;
  }
}

.buttonV3 {
  width: 200px;
  height: 50px;
  background: $main-color-1;
  border: 1px solid $main-color-1;
  border-radius: 3px;
  font-size: 17px;
  color: #fff;
  letter-spacing: 4px;
  text-transform: uppercase;
  text-decoration: none;
}

.slogan {
  font-family: 'effra-b';
  font-size: 40px !important;
  letter-spacing: -0.02em;
  text-align: left;
  margin-bottom: 25px;
  color: $main-color-4;
  strong {
    color: $main-color-1;
    font-weight: 400;
  }
}

@media (max-width: 575px) {
  .slogan {
    font-size: 28px !important;
  }
  .buttonV2 {
    font-size: 15px;
  }
}

/*----------HEADER-------------*/

header {
  position: relative;
  display: flex;
  justify-content: space-between;
  align-items: center;
  height: 220px;
  z-index: 99;
  .logo {
    margin-left: 115px;
    img {
      width: 215px;
      max-width: 100%;
    }
  }
}

@media (max-width: 575px) {
  header {
    height: 150px;
    .logo {
      img {
        width: 150px;
      }
    }
  }
}

/*----------FOOTER-------------*/

footer {
  margin-bottom: 120px;
  ul {
    display: flex;
    justify-content: space-around;
    li {
      list-style: none;
      a {
        font-family: 'effra-r';
        font-size: 19px;
        color: #000;
        text-decoration: none;
        &:hover {
          color: $main-color-1;
        }
      }
    }
  }

  .copyright {
    margin-top: 45px;
    .footer-copy {
      display: inline;
      font-family: 'effra-r';
      font-size: 16px;
      color: #bdbdbd;
      border-top: 1px solid #bdbdbd;
      margin-top: 45px;
      padding-top: 10px;
    }
  }
}

@media (max-width: 991px) {
  footer {
    .social-network {
      margin-top: 25px;
      a:nth-of-type(2) {
        margin: 0 15px;
      }
    }
  }
}

/*----------NAVIGATION---------*/
//vars
$fg:#ffc107;
$bg:#009688;
$pi:3.14;

//config
$menu-items:6;
$open-distance:105px;
$opening-angle:$pi*2;

%goo{
  filter:url('#shadowed-goo');
  // debug 
  //background:rgba(255,0,0,0.2);
}
%ball{
  border-radius:100%;
  width:80px;
  height:80px;
  margin-left:0px;
  position:absolute;
  top: 70px;
  right: 115px;
  color:white;
  text-align:center;
  line-height:80px;
  transform:translate3d(0,0,0);
  transition:transform ease-out 200ms;
}
.menu-open{
  display:none;
}
.menu-item{
  border-radius:100%;
  height:80px;
  margin-left:0px;
  position:absolute;
  top:68px;
  right: 95px;
  color:#000;
  opacity: 0;
  text-align:center;
  line-height:85px;
  transform:translate3d(0,0,0);
  transition:transform ease-out 200ms;
}
.hamburger{
  $width:35px;
  $height:4px;
  width:$width;
  height:$height;
  background: $main-color-1;
  display:block;
  position:absolute;
  top:50%;
  left:50%;
  margin-left:-$width/2;
  margin-top:-$height/2;
  transition:transform 200ms;
}
$hamburger-spacing:10px;
.hamburger-1{
  transform:translate3d(0,-$hamburger-spacing,0);
}
.hamburger-2{
  transform:translate3d(0,0,0);
}
.hamburger-3{
  width: 23px;
  transform:translate3d(0,$hamburger-spacing,0);
}
.menu-open:checked+.menu-open-button{
  .hamburger-1{
    transform:translate3d(0,0,0) rotate(45deg); 
  }
  .hamburger-2{
    transform:translate3d(0,0,0) scale(0.1,1);
  }
  .hamburger-3{
    width: 35px;
    transform:translate3d(0,0,0) rotate(-45deg); 
  }
}
.menu{
  @extend %goo;
  $width:650px;
  $height:220px;
  position:relative;
  width:$width;
  height:$height;
  box-sizing:border-box;
  font-size:20px;
  text-align:left;
}


.menu-item{
  text-decoration: none;
  font-size: 22px;
  &:hover{
    background:white;
    color: $main-color-1;
  }
  @for $i from 1 through $menu-items{
    &:nth-child(#{$i+2}){
      transition-duration:180ms;
    }
  }
}

.menu-open-button{
  @extend %ball;
  z-index:2;
  transition-timing-function:cubic-bezier(0.175, 0.885, 0.320, 1.275);
  transition-duration:400ms;
  transform:scale(1.1,1.1) translate3d(0,0,0);
  cursor:pointer;
}
.menu-open-button:hover{
  transform:scale(1,1) translate3d(0,0,0);
}
.menu-open:checked+.menu-open-button{
  transition-timing-function:linear;
  transition-duration:200ms;
  transform:scale(1,1) translate3d(0,0,0);
}

.menu-open:checked~.menu-item{
  transition-timing-function:cubic-bezier(0.165, 0.840, 0.440, 1.000);
  opacity: 1;
  @for $i from 1 through $menu-items{
    @if $i == 5 {
      &:nth-child(#{$i+2}){
        transition-duration:90ms+(80ms*$i);
        transform:translate3d((-120px*$i)-30px,0,0);
      }
    } @else if $i == 6 {  
      &:nth-child(#{$i+2}){
        transition-duration:90ms+(80ms*$i);
        transform:translate3d((-120px*$i)-60px,0,0);
      }
    } @else {
      &:nth-child(#{$i+2}){
        transition-duration:90ms+(80ms*$i);
        transform:translate3d((-120px*$i),0,0);
      }
    }
  }
}

@media (max-width: 1399px) {
  header {
    .logo {
      margin-left: 35px;
    }
    .menu-open-button {
      right: 35px;
    }
    .menu-item {
      right: 0;
    }
  }
}

.icon-1, .icon-2, .icon-3 {
  position: absolute;
  left: 25%;
  top: 50%;
  width: 35px;
  height: 4px;
  background-color: $main-color-1;
  transition: all 400ms cubic-bezier(.84,.06,.52,1.8);
}

.icon-1 {
transform: translateY(-10px);
animation-delay: 100ms;
}

.icon-3 {
transform: translateY(10px);
animation-delay: 250ms;
width: 25px;
}

.hamburger-icon {
  position: absolute;
  height: 60px;
  width: 60px;
  top: 85px;
  right: 35px;
  z-index: 1000;
  cursor: pointer;
  border-radius: 50%;
  transition: all 0.2s ease-in-out;
  &:hover {
    // .icon-1 {
    //   transform: rotate(40deg);
    // }
    // .icon-3 {
    //   transform: rotate(-40deg);
    // }
    // .icon-2 {
    //   opacity: 0;
    // }
    transform: scale(1);
  }
}

.icon-1.a {
transform: rotate(40deg);
background-color: $main-color-4;
}
.icon-3.b {
transform: rotate(-40deg);
background-color: $main-color-4;
width: 35px;
}
.icon-2.c {
opacity: 0;
}

@keyframes slideIn {
  0% {
    width: 0%;
    // transform: scale(.3);
    opacity: 0;
  }
  
  100% {
    width: 50%;
    // transform:scale(1);
    opacity: 1;
  }
}

@media (max-width: 575px) {
  .hamburger-icon {
    top: 45px;
  }
}

nav#nav {
  background: $main-color-1;
  position: fixed;
  top: 0;
  right: 0;
  height: 100%;
  min-height: 100vh;
  width: 0%;
  z-index: 10;
  opacity: 0;
  transition: all 600ms cubic-bezier(.62,.04,.3,1.56);
  transition-delay: 100ms;
  z-index: 99;
  ul {
    margin: 0;
    position: absolute;
    top: 30%;
    left: 40%;
    li {
      text-align: right;
      list-style: none;
      font-size: 24px;
      color: #fff;
      line-height: 2.2;
      text-transform: uppercase;
      letter-spacing: 1.7px;
      a {
        font-family: "effra-b";
        color: #fff;
        text-decoration: none;
      }
      &:before {
        
      }
    }
  }
}

nav.show {
  //  opacity: 0;
  // animation: slideIn 0.6s cubic-bezier(.62,.04,.3,1.56);
  // animation-fill-mode: forwards;
   width: 50% !important;
    // transform:scale(1);
   opacity: 1 !important;
}


.dark-blue {
  position: absolute;
  top: 0;
  left: 0;
  background: $main-color-1;
  height: 100%;
  width: 0%;
  transition: all 500ms cubic-bezier(.62,.04,.3,1.8);
  transition-delay: 50ms;
  z-index: 5;
  opacity: 1;
}

.dark-blue.slide {
  width: 50%;
  opacity: 1;
}

@keyframes shotup {
  0% {
    transform: translateY(300%) ;
    opacity: 0;
  }
  90% {
    transform: translateY(-50px);
  }
  100% {
    transform: translateY(0%) ;
    opacity: 1;
  }
}

.content-nav {
  position: absolute;
  top: 50%;
  left: 50%;
  width: 100%;
  transform: translate(-50%, -50%);
  text-align: center;
  h1 {
    font-size: 70px;
    animation: shotup 600ms ease-in-out;
    animation-delay: 300ms;
  }
}

@media (max-width: 767px) {
  nav.show {
    width: 80% !important;
  }
}

/*--------------------CAROUSEL----------------*/

.carousel{
  height: 1030px;
  overflow: hidden;
  position: relative;
  .container {
    position: relative;
  }
  .header-component {
    position: absolute;
    bottom: 0;
    right: 0;
  }
  .slogan {
    position: absolute;
    bottom: 85px;
  }
  & .list{
    width: 100%;
    height: 1030px;
    margin: auto;
    position: relative;
    & .item{
      position: absolute;
      inset: 0;
      & figure{
        position: absolute;
        width: 100%;
        height: 1030px;
        top: 0;
        & img{
          width: 100%;
          height: 100%;
          max-height: 100%;
          object-fit: cover;
        }/*
        &::before{
            content: '';
            position: absolute;
            background-color: #0b0b1b;
            width: 100%;
            height: 100px;
            top: 150%;
            left: 50px;
            border-radius: 50%;
            filter: blur(50px);
        }*/
    }
    
    & .content{
      z-index: 20;
      width: 100%;
      height: 1030px;
      right: var(--w-column);
      display: flex;
      flex-direction: column;
      justify-content: center;
      gap: 40px;
      & .category{
          font-weight: 500;
      }
      & h2{
        margin-top: -250px;
        font-family: 'conthrax';
        font-size: 70px;
        margin-bottom: -15px;
        text-align: left;
        color: $main-color-1;
        
      }
      & p {
        font-family: 'effra-r';
        font-size: 30px;
        text-align: left;
        color: $main-color-3;
        max-width: 550px;
        margin-bottom: 25px;

      }
    }   
  }
}

.arrows{
  width: min(1200px, 90vw);
  display: none;
  justify-content: space-between;
  position: absolute;
  top: 52%;
  left: 50%;
  transform: translate(-50%, -25px);
  pointer-events: none;
  z-index: 100;
  & button{
      top: 60%;
      width: 50px;
      height: 50px;
      border-radius: 50%;
      border: 1px solid #659cdf55;
      background-color: #232442;
      color: #fff7;
      font-size: large;
      cursor: pointer;
      pointer-events: auto;
      transition: 0.5s;
      &:hover{
          border: 1px solid #659cdf;
          color: #fff;
      }
  }
}
.indicators{
  position: absolute;
  bottom: 225px;
  height: var(--w-column);
  display: flex;
  flex-direction: column;
  pointer-events: none;
  justify-content: end;
  gap: 10px;
  & .number{
      font-family: 'League Gothic';
      font-size: 7vw;
  }
  & ul{
      display: flex;
      gap: 40px;
      & li{
          /* width: 50px; */
          /* font-family: Effra; */
          /* font-weight: normal; */
          font-size: 13px;
          letter-spacing: 0.2em;
          line-height: 40px;
          list-style: none;
          text-transform: uppercase;
          text-align: left;
          color: $main-color-5;
          height: 1px;
          background-color: transparent;
          border-radius: 10px;
          pointer-events: auto;
          cursor: pointer;
          transition: 0.5s;
          &.active{
              background-color: $main-color-4;
              color: $main-color-4;
          }
      }
    }
  }
}

.carousel{
  --calculation: 1; /* 1 or -1 */
  & .list{
    & .item{
      transform: translateX(calc(100vw * var(--calculation)));
      transition:  0.5s;
      opacity: 0;
      & figure{
          & img{
              transform: rotate(0deg);
              transition: transform 1s;
              transition-delay: 0.3s;
          }
      }
      & .content{
          & .category, h2, .description, .more{
              transform: translateX(calc(200px * var(--calculation)));
              transition:  0.7s;
              opacity: 0;
              transition-delay: 0.3s;
          }
          & h2{ transition-delay: 0.5s; }
          & .description{ transition-delay: 0.7s; }
          & .more{ transition-delay: 0.9s; }
      }
      &.active{
          opacity: 1;
          transform: translateX(0);
          & figure{
              & img{
              }
          }
          & .content{
              & .category, h2, .description, .more{
                  transform: translateX(0px);
                  opacity: 1;
              }
          }
      }
      &.activeOld{
          transform: translateX(calc(-100vw * var(--calculation)));
      }
    }
  }
}

@media (max-width: 776px) {
  .carousel {
    height: 830px;
    .indicators {
      bottom: 400px;
      gap: 8px;
      ul {
        gap: 20px;
      }
    }
    .slogan {
      bottom: 260px;
    }
    .list {
      .item {
        .content {
          height: 830px;
          h2 {
            font-size: 40px;
            margin-top: -215px;
          }
          p {
            font-size: 24px;
          }
        }
      }
    }
  }
}

@media (max-width: 575px) {
  .carousel {
    .slogan {
      font-size: 30px !important;
    }
  }
}

/*-------------slider---------------*/
.homepage-ref {
  .red-bar-1 {
    position: absolute;
    bottom: 0;
    left: 200px;
    width: 20px;
    height: 85px;
    background: $main-color-1;
    z-index: 999;
  }
  .red-bar-2 {
    position: absolute;
    bottom: 0;
    right: 200px;
    width: 20px;
    height: 85px;
    background: $main-color-1;
    z-index: 999;
  }
  a {
    text-decoration: none;
    h2 {
      margin-top: 50px;
      font-family: 'conthrax';
      font-size: 50px;
      text-align: center;
      color: $main-color-1;
    }
    &:hover {
      h2 {
        color: $main-color-4;
      }
    }
  }
  #carousel {
    display: flex;
    height: 600px;
    transform-style: preserve-3d;
    justify-content: center;
  }
  .card {
    position: absolute;
    width: 1000px;
    height: 600px;
    transform-style: preserve-3d;
    transition: 1s;
    border: none !important;
    border-radius: 5px 5px 0px 0px;
  }
  .card:nth-child(1) {
    transform: translateX(0) translateZ(0);
    background: url(../images/slider/carousel-1.jpg) center center no-repeat;
  }
  .card:nth-child(2) {
    transform: translateX(-35%) translateY(20%) translateZ(-50px) scale(0.6);
    background: url(../images/slider/carousel-2.jpg) center center no-repeat;
  }
  .card:nth-child(3) {
    transform: translateX(35%) translateY(20%) translateZ(-50px) scale(0.6);
    background: url(../images/slider/carousel-3.jpg) center center no-repeat;
  }
}

.homepage-aboutus {
  p {
    margin-bottom: 60px;
  }
}
@media (max-width: 1375px) {
  #carousel {
    height: 400px !important;
  }
  .homepage-ref {
    .card {
      width: 700px;
      height: 400px;
    }
  }
}

@media (max-width: 991px) {
  #carousel {
    height: 250px !important;
  }
  .homepage-ref {
    h2 {
      margin-bottom: 35px;
    }
    .card {
      width: 550px;
      height: 250px;
    }
  }
}

#homepage-news {
  .news-left-box {
    height: 600px;
    img {
      object-fit: cover;
      height: 100%;
      width: 100%;
    }
  }
  .news-right-box {
    height: 500px;
    padding: 0 130px;
    background-color: $main-color-2;
    .news-sub-text {
      font-family: 'effra-r';
      font-size: 17px;
      letter-spacing: 0.2em;
      color: #000;
      max-width: 720px;
    }

    .news-sub-headline {
      font-family: 'effra-b';
      font-size: 44px;
      color: #000;
      max-width: 720px;
    }

    .news-sub-p {
      font-family: 'effra-r';
      font-size: 19px;
      color: #000;
      line-height: 35px;
      padding: 40px 0 45px;
      max-width: 720px;
    }
  }
}

@media (max-width: 776px) {
  #homepage-news {
    .news-right-box {
      padding: 0 40px;
      .news-sub-headline {
        font-size: 34px;
      }
    }
  }
}
@media (max-width: 575px) {
  #homepage-news {
    .news-right-box {
      padding: 0 20px;
      text-align: center;
    }
  }
}

.page-header {
  position: relative;
  width: 100%;
  height: 730px;
  overflow-x: hidden;
  overflow-y: hidden;
  .container {
    position: relative;
    height: 100%;
  }
  .header-component {
    position: absolute;
    bottom: 0;
    right: 0;
  }
  h1 {
    font-family: 'conthrax';
    font-size: 70px;
    color: $main-color-1;
    margin-top: 25%;
    margin-bottom: 10px;
  }

  p {
    font-family: 'effra-r';
    font-size: 30px;
    text-align: left;
    color: $main-color-3;    
  }

  .other-info {
    font-family: 'effra-r';
    font-size: 17px;
    margin-bottom: 35px;
    letter-spacing: 0.1em;
    margin-bottom: 20px;
    color: #000;
    strong {
      font-weight: 300;
      color: $main-color-1;
    }
  }

  .links {
    margin-top: 58px;
    a {   
    }

  }

  .slogan {

  }

  .slogan-stable-position {
    position: absolute;
    bottom: 80px;
  }
  .component-stable-position {
    position: absolute;
    right: 0;
    bottom: 0;
  }

  .page-contact-header {
    .sanexcz {
      font-family: 'effra-b';
      font-size: 30px;
      color: $main-color-4;
      padding: 20px 0 25px;
    }
    p {
      font-size: 19px !important;
      line-height: 34px;
      strong {
        color: $main-color-1;
        font-weight: 300;
      }
    }
  }
}

@media (max-width: 1199px) {
  .page-header {
    .header-component {
      bottom: 0px
    }
  } 
}

@media (max-width: 997px) {
  .page-header {
    .header-component {
      display: none;
    }
  } 
}

@media (max-width: 776px) {
  .page-header {
    h1 {
      font-size: 50px;
      margin-top: 20%;
    }
    p {
      font-size: 24px;
    }
  }
}
@media (max-width: 575px) {
  .page-header {
    h1 {
      margin-top: 35%;
    }
  }
}

/*------------aboutus------------*/

.page-header-aboutus {
  position: absolute;
  height: 730px;
  left: 0;
  right: 0;
  z-index: -10;
  img {
    height: 100%;
    width: 100%;
    object-fit: cover;
    object-position: top;
  }
}

@media (max-width: 575px) {
  .page-header-aboutus {
    left: -195px;
  }
}

/*--------------whatwedo-------------*/

.aboutus {
  img {
    max-width: 220px;
  }
  .counter-box {
    position: sticky;
    top: 20px;
  }
  .aboutus-counter {
    position: absolute;
    top: 25px;
    width: 220px;
    display: grid;
    grid-template-columns: auto auto auto;
    .counter-1 {
      font-family: 'effra-r';
      font-size: 28px;
      text-align: start;
      color: $main-color-1;
    }
    .counter-3 {
      font-family: 'effra-r';
      font-size: 28px;
      text-align: end;
      color: $main-color-1;
    }
    .counter-2 {
      font-family: 'conthrax';
      margin-top: -50px;
      font-size: 94px;
      text-align: center;
      color: $main-color-4;
    }
  }

  .subtext {
    font-family: 'effra-r';
    font-size: 17px;
    letter-spacing: 0.2em;
    color: #000;
    text-transform: uppercase;
  }

  h2 {
    font-family: 'effra-b';
    font-size: 44px;
    color: #000;
    margin: 10px 0 40px;
  }

  p {
    font-family: 'effra-r';
    font-size: 19px;
    line-height: 35px;
    color: #000;
    margin-bottom: 35px;
    &:last-of-type {
      margin-bottom: 0;
    }
  }
  a {
    margin-top: 60px;
    display: block;
    width: 130px;
  }
}

@media (max-width: 991px) {
  .aboutus {
    .aboutus-counter {
      top: 25px;
    }
    h2 {
      font-size: 28px;
      color: #000;
      margin: 20px 0 20px;
    }
    p {
      margin-bottom: 35px;
    }
    a {
      margin: 60px auto 0;
    }
  }
}

.ssbk {
  p {
    font-family: 'effra-r';
    font-size: 19px;
    line-height: 30px;
    color: #000;
  }
  img {
    max-width: 325px;
  }
}

@media (max-width: 991px) {
  .ssbk {
    img {
      margin-bottom: 50px;
    }
  }
}

.eu {
  .eu-box {
    background: $main-color-2;
    padding: 100px;
    p {
      font-family: 'effra-b';
      font-size: 19px;
      color: $main-color-4;
      padding-bottom: 60px;
      strong {
        color: $main-color-1;
      }
    }

    .eu-slogan {
      p {
        font-family: 'effra-r';
        font-weight: 500;
        font-size: 19px;
        color: #000;
        padding-bottom: 0;
        strong {
          color: $main-color-4;
        }
      }
    }
  }
}

@media (max-width: 1399px) {
  .eu {
    .eu-box {
      padding: 75px;
      .eu-slogan {
        p {
          padding: 30px 0;
        }
      }
    }
  }
}

@media (max-width: 575px) {
  .eu {
    .eu-box {
      padding: 50px 30px;
    }
  }
}

.whatwedo {
  a {
    display: block;
    text-decoration: none;
    transition: all 0.2s ease-out;
    img {
      width: 100%;
      transition: all 0.2s ease-out;
    }
    .linkSlogan {
      font-family: 'conthrax';
      font-size: 45px;
      text-align: center;
      color: $main-color-1;
      margin-top: 85px;
      transition: all 0.2s ease-out;
    }
    &:hover {
      .linkSlogan {
        margin-top: 25px;
      }
    }
  }
}

@media (max-width: 1199px) {
  .whatwedo {
    a {
      .linkSlogan {
        font-size: 28px;
      }
    }
  }
}

@media (max-width: 767px) {
  .whatwedo {
    a {
      .linkSlogan {
        position: absolute;
        bottom: 45px;
        font-size: 26px;
        left: 0;
        right: 0;
      }
    }
  }
}


/*----------------services--------------*/

.services {
  .services-slogan {
    font-family: 'effra-r';
    font-size: 30px;
    text-align: center;
    color: #000;
  }
  .services-header {
    font-family: 'conthrax';
    font-size: 45px;
    text-align: center;
    color: $main-color-1;
    margin: 100px 0;
  }

  .services-box {
    background: $main-color-2;
    padding: 100px 180px;
    margin-bottom: 100px;
    p {
      font-family: 'effra-r';
      font-size: 19px;
      color: $main-color-4;
      line-height: 34px;
      margin-bottom: 35px;
      &:last-of-type {
        margin-bottom: 0;
      }
      i {
        font-family: 'effra-li';
      }
    }
  }
  .services-small-box {
    margin-bottom: 40px;
    .services-small-box-text {
      background: $main-color-2;
      padding: 80px 120px;
      .small-box-header {
        font-family: 'effra-b';
        font-size: 30px;
        text-align: center;
        color: $main-color-4;
        margin-bottom: 30px;
      }
      .small-box-text {
        font-family: 'effra-r';
        font-size: 19px;
        text-align: center;
        color: $main-color-4;
        line-height: 34px;
      }
    }
    &:last-of-type {
      margin-bottom: 100px;
    }
  }
}

@media (max-width: 1199px) {
  .services {
    .services-small-box {
      .services-small-box-text {
        padding: 60px 60px;
      }
    }
  }
}

@media (max-width: 991px) {
  .services {
    .services-box {
      padding: 100px 80px;
    }
  }
}

@media (max-width: 575px) {
  .services {
    .services-box {
      padding: 45px;
    }
  }
}


/*-------------reference------------*/
/* ---- grid ---- */

#wrapper {
  margin-bottom: 100px;
  .button-group {
    margin-bottom: 100px;
    margin-top: -80px;
  }
}

.grid-sizer,.grid-item{
	width:100%;
	margin: 0px;
	padding:0px;
	vertical-align: center;
	margin-bottom:0px;
  display: flex;
  justify-content: center;
  align-items: center;
}

.grid-item {
  overflow: hidden;
  img {
    height: 100%;
    object-fit: cover;
    margin-left: -10px;
  }
}

@media only screen and (min-width:580px){
	.grid-sizer,.grid-item{
    width:50%;
    margin: 0px;
    padding:0px;
    vertical-align: center;
    margin-bottom:0px;
  }
}

@media only screen and (min-width:767px){
	.grid-sizer,.grid-item{
    width:33.3%;
    margin: 0px;
    padding:0px;
    vertical-align: center;
    padding: 10px;
    margin-bottom:0px;
  }
}

@media (max-width: 575px) {
  #wrapper {
    .button-group {
      flex-wrap: wrap;
      gap: 10px;
      margin-bottom: 80px;
      .buttonV2 {
        margin: 0px 15px;
      }
    }
  }
}

/*----------------news-----------------*/

.carreer {
  h2 {
    font-family: 'conthrax';
    font-size: 45px;
    text-align: center;
    color: $main-color-1;
    margin-bottom: 100px;
  }
  .carreer-small-box {
    margin-bottom: 40px;
    .carreer-small-box-text {
      background: $main-color-2;
      padding: 0px 100px;
      min-height: 350px;
      display: flex;
      flex-direction: column;
      justify-content: center;
      .small-box-header {
        font-family: 'effra-b';
        font-size: 30px;
        text-align: center;
        color: $main-color-4;
        margin-bottom: 50px;
      }
      a {
        width: 130px;
        margin: 0 auto;
      }
    }
  }
}

.news-detail {
  background-color: $main-color-2;
  padding: 100px 0;
  .news-detail-header {
    font-family: 'effra-r';
    font-size: 17px;
    letter-spacing: 0.2em;
    text-align: center;
    color: #000;
  }
  h1 {
    font-family: "conthrax";
    font-size: 45px;
    /* text-align: center; */
    color: #E82837;
    margin-bottom: 85px;
  }
  h2 {
    font-family: 'effra-b';
    font-size: 30px;
    color: #000;
    margin-bottom: 30px;
  }
  ul {
    margin-bottom: 30px;
    margin-left: 20px;
    li {
      font-family: 'effra-r';
      font-size: 19px;
      line-height: 34px;
      color: #000;
      list-style: square;
      &::marker {
        color: $main-color-1;
        font-size: 25px;
      }
    }
  }
  .info-last {
    ul {
      margin-bottom: 0px;
      li {
        list-style: square !important;
        &::marker {
          color: $main-color-4 !important;
        }
        a {
          font-family: 'effra-m' !important;
          color: $main-color-1 !important;
        }
      }
    }
  }
}

@media (max-width: 575px) {
  .carreer {
    .carreer-small-box {
      .carreer-small-box-text {
        padding: 0 35px;
      }
    }
  }

  .news-detail {
    h1 {
      font-size: 34px;
    }
  }
}

/*------------news------------*/

.news {
  .news-left-box, .news-right-box {
    height: 620px;
    background-color: $main-color-2;
  }
  img {
    height: 100%;
    width: 100%;
    object-fit: cover;
  }
  .news-left-box-box, .news-right-box-box {
    max-width: 720px;
    height: 620px;
    padding: 0 75px;
    display: flex;
    align-items: flex-start;
    flex-direction: column;
    justify-content: center;
    .news-type {
      font-family: 'effra-r';
      font-size: 17px;
      letter-spacing: 0.2em;
      color: #000;      
    }
    .news-header {
      font-family: 'effra-b';
      font-size: 44px;
      line-height: 60px;
      color: #000;   
      margin: 0 0 50px;   
    }
    .news-text {
      font-family: 'effra-r';
      font-size: 19px;
      color: #000;
      margin-bottom: 50px;
    }
  }
  .news-left-box {
    display: flex;
    justify-content: flex-end;
  }
  .news-right-box {
    display: flex;
    justify-content: flex-start;
  }

  .news-box {
    background: $main-color-2;
    padding: 100px 185px;
    p {
      font-family: 'effra-r';
      font-size: 19px;
      line-height: 34px;
      color: $main-color-4;
      margin-bottom: 35px;
      &:last-of-type {
        margin-bottom: 0;
      }
      i {
        font-family: 'effra-li';
      }
    }
  }
  .news-detail-header {
    font-family: 'effra-r';
    font-size: 17px;
    letter-spacing: 0.2em;
    text-align: center;
    color: #000;
  }
  h1 {
    font-family: 'conthrax';
    font-size: 45px;
    text-align: center;
    color: $main-color-1;
    margin: 20px 0 100px;
  }
}

@media (max-width: 1199px) {
  .news {
    .news-box {
      padding: 100px 100px;
    }
  }
}

@media (max-width: 991px) {
  .news {
    .news-right-box .news-right-box-box  {
      justify-content: center;
      align-items: center;
      text-align: center;
    }
  }
}

@media (max-width: 767px) {
  .news {
    .news-box {
      padding: 20px 20px;
    }
    .news-right-box {
      align-items: center;
      .news-right-box-box {
        padding: 0 20px;
      }
    }
  }
}

@media (max-width: 575px) {
  .news {
    .news-box {
      padding: 50px 20px;
    }
  }
}

/*---------------contact---------------*/

.contact-sanex {
  .contact-box {
    background-color: $main-color-2;
    padding: 85px 45px;
    .contact-box-header {
      font-family: 'effra-b';
      font-size: 30px;
      text-align: center;
      color: $main-color-4;
      margin-bottom: 25px;
    }
    .contact-box-text {
      font-family: 'effra-r';
      font-size: 19px;
      text-align: center;
      color: $main-color-4;
      strong {
        color: $main-color-1;
        font-weight: 300;
      }
    }
  }

  h2 {
    font-family: 'conthrax';
    font-size: 45px;
    text-align: center;
    color: $main-color-1;
    margin-bottom: 100px;
  }

  .contact-box-classic {
    margin-bottom: 90px;
    .contact-box-name {
      font-family: 'effra-b';
      font-size: 30px;
      text-align: center;
      color: $main-color-4;
    }
    .contact-box-position {
      font-family: 'effra-r';
      font-size: 19px;
      text-align: center;
      color: $main-color-4;
      padding-bottom: 15px;
    }
    .contact-box-position-red {
      color: $main-color-1 !important;
    }
    .contact-box-contact {
      font-family: 'effra-r';
      font-size: 19px;
      text-align: center;
      color: $main-color-4;
      strong {
        color: $main-color-1;
        font-weight: 300;
      }
    }
  }
}

@media (max-width: 575px) {
  .contact-sanex {
    .contact-box {
      padding: 50px 20px;
    }
  }
}